import { AppAbilityKey } from '../enums/ability-keys.enum';
import { Role } from '../enums/role.enum';

export interface ISubTabItems {
  title: string;
  route: string;
  abilityKey?: string,
  eligibleRoles?: Role[],
}

export interface ISidebarItem {
  title: string;
  icon: string;
  route?: string;
  abilityKey: string | string[],
  children?: ISubTabItems[];
  elementClass?: string;
  eligibleRoles?: Role[];
}

const sortByTitle = (a: { title: string; }, b: { title: string; }) => {
  const titleA = a.title.toUpperCase();
  const titleB = b.title.toUpperCase();

  if (titleA < titleB) {
    return -1;
  }
  if (titleA > titleB) {
    return 1;
  }

  return 0;
};

export const COOK_ADMIN_SIDEBAR_ITEMS: ISidebarItem[] = [
  //   {
  //   title: 'Dashboard',
  //   icon: 'dashboard.svg',
  //   route: '',
  //   abilityKey: AppAbilityKey.COOK_ADMIN_ORDER_DASHBOARD,
  // },
  {
    title: 'Cook Banner Management',
    icon: 'icon-banner-management.svg',
    route: 'banner-management',
    abilityKey: AppAbilityKey.COOK_ADMIN_BANNER_MANAGEMENT,
  },
  {
    title: 'Schedule Dashboard',
    icon: 'icon-schedule-dashboard.svg',
    route: 'schedule-dashboard',
    abilityKey: AppAbilityKey.COOK_ADMIN_SCHEDULE_DASHBOARD,
  }, {
    title: 'View Cart Items',
    icon: 'view-cart.svg',
    route: 'view-cart-items',
    abilityKey: AppAbilityKey.COOK_ADMIN_VIEW_CART_ITEMS,
  },
  {
    title: 'View Seller',
    icon: 'view-cook.svg',
    route: 'view-cook',
    abilityKey: AppAbilityKey.COOK_ADMIN_VIEW_COOK,
  },
  // , {
  //   title: 'New Cook Onboard',
  //   icon: 'new-cook-onboard.svg',
  //   route: 'add-cook',
  //   abilityKey: AppAbilityKey.COOK_ADMIN_NEW_COOK_ONBOARD,
  //   children: [{
  //     title: 'Personal Information',
  //     route: 'personal-information',
  //     // abilityKey: AppAbilityKey.COOK_ADMIN_COOK_PERSONAL_INFORMATION,
  //   }, {
  //     title: 'Bank Details',
  //     route: 'bank-details',
  //     // abilityKey: AppAbilityKey.COOK_ADMIN_COOK_BANK_DETAILS,
  //   }, {
  //     title: 'Document Details',
  //     route: 'document-details',
  //     // abilityKey: AppAbilityKey.COOK_ADMIN_COOK_DOCUMENT_DETAILS,
  //   }, {
  //     title: 'Terms and Conditions',
  //     route: 'terms-conditions',
  //     // abilityKey: AppAbilityKey.COOK_ADMIN_COOK_TERMS_AND_CONDITIONS,
  //   }, {
  //     title: 'Kitchen View',
  //     route: 'kitchen-view',
  //     // abilityKey: AppAbilityKey.COOK_ADMIN_COOK_KITCHEN_VIEWS,
  //   }],
  // }
  // , {
  //   title: 'Kitchen Onboarding',
  //   route: 'cook-kitchen-onboarding',
  //   icon: 'kitchen-onboarding.svg',
  //   abilityKey: AppAbilityKey.COOK_ADMIN_KITCHEN_ONBOARDING,
  //   children: [{
  //     title: 'Kitchen setup',
  //     route: 'kitchen-setup',
  //     abilityKey: AppAbilityKey.COOK_ADMIN_FOOD_SELLER_PROFILE,
  //   }],
  // }
  {
    title: 'Seller Profile Approval',
    icon: 'kitchenApproval.svg',
    route: 'seller-profile-approval',
    abilityKey: AppAbilityKey.COOK_ADMIN_SELLER_PROFILE_APPROVAL,
    children: [
      {
        title: 'New',
        route: 'new-profile-approval',
        abilityKey: AppAbilityKey.COOK_ADMIN_SELLER_PROFILE_APPROVAL,
      },
      {
        title: 'Existing',
        route: 'existing-profile-approval',
        abilityKey: AppAbilityKey.COOK_ADMIN_SELLER_PROFILE_APPROVAL,
      },
    ],
  }, {
    title: 'Seller Profile',
    icon: 'kitchen-profile.svg',
    route: 'seller-profile',
    abilityKey: AppAbilityKey.COOK_ADMIN_SELLER_PROFILE,
    // children: [{
    //   title: 'Add Menu',
    //   route: 'add-menu',
    //   abilityKey: AppAbilityKey.COOK_ADMIN_ADD_MENU,
    // },
    // // {
    // //   title: 'Kitchen Availability',
    // //   route: 'kitchen-availability',
    // // },
    // {
    //   title: 'Schedule Menu',
    //   route: 'schedule-menu',
    //   abilityKey: AppAbilityKey.COOK_ADMIN_SCHEDULE_MENU,
    // }, {
    //   title: 'Earnings',
    //   route: 'earnings',
    //   abilityKey: AppAbilityKey.COOK_ADMIN_COOK_EARNINGS,
    // }, {
    //   title: 'Payouts',
    //   route: 'payouts',
    //   abilityKey: AppAbilityKey.COOK_ADMIN_COOK_PAYOUTS,
    // }, {
    //   title: 'Order History',
    //   route: 'order-history',
    //   abilityKey: AppAbilityKey.COOK_ADMIN_KITCHEN_ORDER_HISTORY,
    // }, {
    //   title: 'Kitchen Inspection',
    //   route: 'kitchen-inspection',
    //   abilityKey: AppAbilityKey.COOK_ADMIN_KITCHEN_INSPECTION,
    // }],
  }, {
    title: 'Pre-booking Configuration',
    icon: 'icon-prebooking-configuration.svg',
    route: 'pre-booking',
    abilityKey: AppAbilityKey.COOK_ADMIN_PRE_BOOKING_CONFIGURATION,
  }, {
    title: 'Menu Approval',
    icon: 'menu-approval.svg',
    route: 'menu-approval',
    abilityKey: AppAbilityKey.COOK_ADMIN_MENU_APPROVAL,
    children: [{
      route: 'new-menu',
      title: 'New Menu',
      abilityKey: AppAbilityKey.COOK_ADMIN_MENU_APPROVAL,
    }, {
      route: 'updated-menu',
      title: 'Updated Menu',
      abilityKey: AppAbilityKey.COOK_ADMIN_MENU_APPROVAL,
    }],
  }, {
    title: 'Product Approval',
    icon: 'menu-approval.svg',
    route: 'product-approval',
    abilityKey: AppAbilityKey.COOK_ADMIN_PRODUCT_APPROVAL,
    children: [{
      route: 'new-product',
      title: 'New Product',
      abilityKey: AppAbilityKey.COOK_ADMIN_PRODUCT_APPROVAL,
    }, {
      route: 'updated-product',
      title: 'Updated Product',
      abilityKey: AppAbilityKey.COOK_ADMIN_PRODUCT_APPROVAL,
    }],
  }, {
    title: 'Product Category',
    icon: 'product-category.svg',
    route: 'product-category',
    abilityKey: AppAbilityKey.COOK_ADMIN_PRODUCT_CATEGORY,
    children: [{
      route: 'category',
      title: 'Category',
      abilityKey: AppAbilityKey.COOK_ADMIN_PRODUCT_CATEGORY,
    }, {
      route: 'sub-category',
      title: 'Sub Category',
      abilityKey: AppAbilityKey.COOK_ADMIN_PRODUCT_CATEGORY,
    }],
  },
  {
    title: 'Cook Payouts',
    icon: 'cookPayout.svg',
    route: 'cook-payouts',
    abilityKey: [
      AppAbilityKey.COOK_ADMIN_COOK_PAYOUTS,
      AppAbilityKey.COOK_ADMIN_PRODUCT_PAYOUTS,
      AppAbilityKey.COOK_ADMIN_SUBSCRIPTION_PAYOUTS,
    ],
    // children: [{
    //   route: 'generate-cook-payout',
    //   title: 'Generate cook payout',
    //   abilityKey: AppAbilityKey.COOK_ADMIN_GENERATE_COOK_PAYOUTS,
    // }, {
    //   route: 'view-cook-payout',
    //   title: 'View cook payout',
    //   abilityKey: AppAbilityKey.COOK_ADMIN_VIEW_COOK_PAYOUTS,
    // }],
  }, {
    title: 'Orders History',
    icon: 'history.svg',
    route: 'orders-history',
    abilityKey: AppAbilityKey.COOK_ADMIN_ORDER_HISTORY,
    children: [
      {
        title: 'Todays Orders',
        route: 'todays-orders',
        abilityKey: AppAbilityKey.COOK_ADMIN_TODAYS_ORDERS,
      },
      {
        title: 'View All Orders',
        route: 'view-all-orders',
        abilityKey: AppAbilityKey.COOK_ADMIN_VIEW_ALL_ORDERS,
      },
      // {
      //   title: 'Edit Orders',
      //   route: 'edit-orders',
      // },
      {
        title: 'Kitchen more than 5 Order',
        route: 'kitchens-orders',
        abilityKey: AppAbilityKey.COOK_ADMIN_KITCHEN_MORE_THAN_5_ORDERS,
      }],
  },
  // {
  //   title: 'Cook Lead Interest',
  //   icon: 'cook-lead-interest.svg',
  //   route: 'cook-lead-interest',
  //   abilityKey: AppAbilityKey.COOK_ADMIN_COOK_LEAD_INTEREST,
  // },
  {
    title: 'Zone List',
    icon: 'zone-list.svg',
    route: 'zone-list',
    abilityKey: AppAbilityKey.COOK_ADMIN_ZONE_LIST,
    eligibleRoles: [Role.SUPER_MASTER_ADMIN],
    children: [{
      title: 'Add Parent Zone',
      route: 'add-parent-zone',
      abilityKey: AppAbilityKey.COOK_ADMIN_ADD_PARENT_ZONE,
    }, {
      title: 'Add Sub Zone',
      route: 'add-sub-zone',
      abilityKey: AppAbilityKey.COOK_ADMIN_ADD_SUB_ZONE,
    }, {
      title: 'Parent Zone List',
      route: 'parent-zone-list',
      abilityKey: AppAbilityKey.COOK_ADMIN_PARENT_ZONE_LIST,
    }, {
      title: 'Zone List',
      route: 'zone-list',
      abilityKey: AppAbilityKey.COOK_ADMIN_SUB_ZONE_LIST,
    }, {
      title: 'Sub-Zone Listing of Kitchens',
      route: 'kitchen-zone-list',
      abilityKey: AppAbilityKey.COOK_ADMIN_SUB_ZONE_LISTING_OF_KITCHENS,
    }],
  }, {
    title: 'Cook Reviews',
    icon: 'cook-reviews.svg',
    route: 'cook-reviews',
    abilityKey: AppAbilityKey.COOK_ADMIN_COOK_REVIEWS,
  }, {
    title: 'Cuisine Management',
    icon: 'cuisine-management.svg',
    route: 'cuisine',
    abilityKey: AppAbilityKey.COOK_ADMIN_CUISINE_MANAGEMENT,
    children: [{
      title: 'Add Cuisine',
      route: 'add-cuisine',
      abilityKey: AppAbilityKey.COOK_ADMIN_ADD_CUISINE,
    }, {
      title: 'Cuisine List',
      route: 'cuisine-list',
      abilityKey: AppAbilityKey.COOK_ADMIN_CUISINE_LIST,
    }],
  }, {
    title: 'Coupon Management',
    icon: 'coupon-management.svg',
    route: 'coupon',
    abilityKey: AppAbilityKey.COOK_ADMIN_COUPON_MANAGEMENT,
    children: [{
      title: 'Coupons',
      route: 'food-coupons',
      abilityKey: AppAbilityKey.COOK_ADMIN_COUPONS_MANAGEMENT_LIST,
    }, {
      title: 'Coupons',
      route: 'product-coupons',
      abilityKey: AppAbilityKey.COOK_ADMIN_PRODUCT_COUPONS_MANAGEMENT_LIST,
    }, {
      title: 'Food Offers',
      route: 'food-offers',
      abilityKey: AppAbilityKey.COOK_ADMIN_OFFERS_MANAGEMENT_LIST,
    }, {
      title: 'Product Offers',
      route: 'product-offers',
      abilityKey: AppAbilityKey.COOK_ADMIN_PRODUCT_OFFERS_MANAGEMENT_LIST,
    }],
    eligibleRoles: [Role.SUPER_MASTER_ADMIN],
  }, {
    title: 'Broadcast Message',
    icon: 'icon-broadcast-message.svg',
    route: 'broadcast-message',
    abilityKey: AppAbilityKey.COOK_ADMIN_BROADCAST_MESSAGE,
  }, {
    title: 'Satellite Store Config',
    icon: 'icon-broadcast-message.svg',
    route: 'satellite-store',
    abilityKey: AppAbilityKey.COOK_ADMIN_SATELLITE_STORE_CONFIGURATION,
  },
].sort(sortByTitle);

export const DRIVER_ADMIN_SIDEBAR_ITEMS: ISidebarItem[] = [
  //   {
  //   title: 'Dashboard',
  //   icon: 'dashboard.svg',
  //   route: '',
  //   abilityKey: AppAbilityKey.DE_ADMIN_DASHBOARD,
  // },
  {
    title: 'Rider Banner Management',
    icon: 'icon-banner-management.svg',
    route: 'banner-management',
    abilityKey: AppAbilityKey.DE_ADMIN_BANNER_MANAGEMENT,
  }, {
    title: 'View DE',
    icon: 'view-cook.svg',
    route: 'view-de',
    abilityKey: AppAbilityKey.DE_ADMIN_VIEW_DE,
  }, {
    title: 'New DE Onboard',
    icon: 'new-cook-onboard.svg',
    route: 'add-de',
    abilityKey: AppAbilityKey.DE_ADMIN_NEW_DE_ONBOARD,
    children: [{
      title: 'Personal Information',
      route: 'personal-information',
      // abilityKey: AppAbilityKey.DE_ADMIN_PERSONAL_INFORMATION,
    }, {
      title: 'Document Details',
      route: 'document-details',
      // abilityKey: AppAbilityKey.DE_ADMIN_DOCUMENT_DETAILS,
    }, {
      title: 'Bank Details',
      route: 'bank-details',
      // abilityKey: AppAbilityKey.DE_ADMIN_BANK_DETAILS,
    }, {
      title: 'Asset Details',
      route: 'asset-details',
      // abilityKey: AppAbilityKey.DE_ADMIN_ASSET_DETAILS,
    }, {
      title: 'Terms and Conditions',
      route: 'terms-conditions',
      // abilityKey: AppAbilityKey.DE_ADMIN_TERMS_AND_CONDITIONS,
    }],
  }, {
    title: 'Rider Reviews',
    icon: 'cook-reviews.svg',
    route: 'rider-reviews',
    abilityKey: AppAbilityKey.DE_ADMIN_RIDER_REVIEWS,
  }, {
    title: 'Orders History',
    icon: 'delivery-status.svg',
    route: 'orders-history',
    abilityKey: AppAbilityKey.DE_ADMIN_DELIVERY_STATUS,
  },
  // {
  //   title: 'Km Logs',
  //   icon: 'km-logs.svg',
  //   route: 'rider-distance-logs',
  //   abilityKey: AppAbilityKey.DE_ADMIN_KM_LOGS,
  // },
  {
    title: 'Rider Status Logs',
    icon: 'de-logs.svg',
    route: 'rider-status-logs',
    abilityKey: AppAbilityKey.DE_ADMIN_RIDER_STATUS_LOGS,
  }, {
    title: 'Rider Connectivity Logs',
    icon: 'rider-connectivity-logs.svg',
    route: 'rider-connectivity-logs',
    abilityKey: AppAbilityKey.DE_ADMIN_RIDER_ERROR_LOGS,
  }, {
    title: 'Rider Location Tracking',
    icon: 'rider-location-tracking.svg',
    route: 'rider-location-tracking',
    abilityKey: AppAbilityKey.DE_ADMIN_RIDER_LOCATION_TRACKING,
  }, {
    title: 'DE Payouts Mastercard',
    icon: 'de-payouts-mastercard.svg',
    route: 'de-payouts',
    abilityKey: AppAbilityKey.DE_ADMIN_PAYOUTS_MASTERCARD,
    children: [{
      title: 'View Mastercard',
      route: 'view-mastercard',
      abilityKey: AppAbilityKey.DE_ADMIN_VIEW_MASTER_CARD,
    }, {
      title: 'Add Mastercard',
      route: 'add-mastercard',
      abilityKey: AppAbilityKey.DE_ADMIN_ADD_MASTER_CARD,
    }],
  }, {
    title: 'Rider Leads',
    icon: 'rider-leads.svg',
    route: 'rider-leads',
    abilityKey: AppAbilityKey.DE_ADMIN_RIDER_LEAD,
  }].sort(sortByTitle);

export const CUSTOMER_ADMIN_SIDEBAR_ITEMS: ISidebarItem[] = [{
  title: 'Banner Management',
  icon: 'icon-banner-management.svg',
  route: 'banner-management',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_BANNER_MANAGEMENT,
}, {
  title: 'Android Configuration Management',
  icon: 'androidConfiguration.svg',
  route: 'android-configuration-management',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_ANDRIOD_CONFIGURATION_MANAGEMENT,
}, {
  title: 'IOS Configuration Management',
  icon: 'IOS-Configuration-Management.svg',
  route: 'ios-configuration-management',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_IOS_CONFIGURATION_MANAGEMENT,
}, {
  title: 'Section Card Management',
  icon: 'sectionCardManagement.svg',
  route: 'section-card-management',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_SECTION_CARD_MANAGEMENT,
  children: [{
    title: 'View Section Card',
    route: 'food-sections/view',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_VIEW_SECTION_CARDS,
  }, {
    title: 'Section Card Sequence',
    route: 'food-sections/sequence',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_SECTION_CARD_SEQUENCE,
  }, {
    title: 'Product Section Card',
    route: 'product-sections/view',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_PRODUCT_SECTION_CARDS,
  }, {
    title: 'Product Section Card Sequence',
    route: 'product-sections/sequence',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_PRODUCT_SECTION_CARDS_SEQUENCE,
  },
  ],
}, {
  title: 'Time Slot Management',
  icon: 'timeSlotManagement.svg',
  route: 'time-slot-management',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_TIME_SLOT_MANAGEMENT,
  children: [{
    route: 'delivery-time',
    title: 'Delivery Time',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_DELIVERY_TIME,
  }, {
    route: 'cook-time',
    title: 'Cook Time',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_COOK_START_ORDER_TIME,
  }],
}, {
  title: 'Calendar Events Management',
  icon: 'calendarEventManangment.svg',
  route: 'calendar-events-management',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_CALENDAR_EVENTS_MANAGEMENT,
}, {
  title: 'Customer Account Configuration',
  icon: 'customerGeneralConfiguration.svg',
  route: 'customer-general-configuration',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_GENERAL_CONFIGURATION,
  children: [{
    route: 'manage-account',
    title: 'Manage Account',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_MANAGE_ACCOUNT_CONFIGURATION,
  }],
},
{
  title: 'Referral Configuration Management',
  icon: 'refferal-configuration-management.svg',
  route: 'referral-configuration-management',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_REFERRAL_CONFIGURATION_MANAGEMENT,
}, {
  title: 'Referral Logs',
  icon: 'refferal-logs.svg',
  route: 'referral-logs',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_REFERRAL_LOGS,
}, {
  title: 'Notifications',
  icon: 'bell-icon.svg',
  route: 'notifications',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_PUSH_NOTIFICATION,
  children: [{
    route: 'push-notification',
    title: 'Push Notification',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_PUSH_NOTIFICATION,
  },
    //  {
    //   route: 'order-history',
    //   title: 'Order history',
    //   abilityKey: AppAbilityKey.CUSTOMER_ADMIN_PUSH_NOTIFICATION,
    // }
  ],
},
{
  title: 'Orders History',
  icon: 'history.svg',
  route: 'orders-history',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_PURCHASE_ORDER_HISTORY,
},
// {
//   title: 'Packaging Charge Configuration',
//   icon: 'general-configuration.svg',
//   route: 'packaging-charges-configuration',
//   abilityKey: AppAbilityKey.CUSTOMER_ADMIN_PACKAGING_CHARGES_CONFIGURATION,
//   children: [{
//     route: 'packaging-charges',
//     title: 'Packaging Charges',
//     abilityKey: AppAbilityKey.CUSTOMER_ADMIN_PACKAGING_CHARGES,
//   }],
// },
{
  title: 'Place Order',
  icon: 'place-order.svg',
  route: 'place-order',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_PLACE_ORDER,
  children: [{
    route: 'create-order',
    title: 'Create order',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_CREATE_ORDER,
  }, {
    route: 'order-history',
    title: 'Order history',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_CUSTOMER_ORDER_HISTORY,
  }],
}, {
  title: 'Customer App Configurations',
  icon: 'map-api-configuration.svg',
  route: 'customer-app-configuration',
  abilityKey: (AppAbilityKey.CUSTOMER_ADMIN_MAP_API_CONFIGURATION
    || AppAbilityKey.CUSTOMER_ADMIN_GREETING_MSG_CONFIGURATION
    || AppAbilityKey.CUSTOMER_ADMIN_FEATURE_LIST_CONFIGURATION
    || AppAbilityKey.CUSTOMER_ADMIN_DELIVERY_PREFERENCE_CONFIGURATION
    || AppAbilityKey.CUSTOMER_ADMIN_GENERAL_CONFIGURATION
    || AppAbilityKey.CUSTOMER_ADMIN_GET_IT_IMMEDIATELY_CONFIGURATION
    || AppAbilityKey.CUSTOMER_ADMIN_RUSHING_KITCHEN_CONFIGURATION),
  children: [{
    route: 'general-configuration',
    title: 'General',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_GENERAL_CONFIGURATION,
  },
  {
    route: 'greeting-message-configuration',
    title: 'Greeting Message',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_GREETING_MSG_CONFIGURATION,
  },
  {
    route: 'feature-list-configuration',
    title: ' Feature List',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_FEATURE_LIST_CONFIGURATION,
  },
  {
    route: 'delivery-preference-configuration',
    title: 'Delivery Preference',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_DELIVERY_PREFERENCE_CONFIGURATION,
  },
  {
    route: 'get-it-immediately-configuration',
    title: 'Get It Immediately',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_GET_IT_IMMEDIATELY_CONFIGURATION,
  },
  {
    route: 'rush-in-kitchen-configuration',
    title: 'Rush in kitchen',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_RUSHING_KITCHEN_CONFIGURATION,
  },
  ],
}, {
  title: 'Tax & Charges',
  icon: 'delivery-charges.svg',
  route: 'delivery-charge-configuration',
  abilityKey: (AppAbilityKey.CUSTOMER_ADMIN_DELIVERY_CHARGES
    || AppAbilityKey.CUSTOMER_ADMIN_DELIVERY_CHARGE_SUGGESTION
    || AppAbilityKey.CUSTOMER_ADMIN_PLATFORM_FEE
    || AppAbilityKey.CUSTOMER_ADMIN_SEARCH_VENDOR_CONFIGURATION
    || AppAbilityKey.CUSTOMER_ADMIN_SMALL_ORDER_FEE_CONFIG
    || AppAbilityKey.CUSTOMER_ADMIN_TAX_CONFIGURATION),
}, {
  title: 'Customer Management',
  icon: 'customer-management.svg',
  route: 'customer-management',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_CUSTOMER_MANAGEMENT,
}, {
  title: 'Control Tower Configuration',
  icon: 'control-tower-configuration.svg',
  route: 'control-tower-configuration',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_CONTROL_TOWER_CONFIGURATION,
}, {
  title: 'COD Configuration',
  icon: 'customer-general-configurations.svg',
  route: 'cod-configuration',
  abilityKey: [AppAbilityKey.CUSTOMER_ADMIN_FOOD_COD_CONFIGURATION,
  AppAbilityKey.CUSTOMER_ADMIN_PRODUCT_COD_CONFIGURATION],
  children: [{
    title: 'Food COD Configuration',
    route: 'food-cod-config',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_FOOD_COD_CONFIGURATION,
  }, {
    title: 'Product COD Configuration',
    route: 'product-cod-config',
    abilityKey: AppAbilityKey.CUSTOMER_ADMIN_PRODUCT_COD_CONFIGURATION,
  }],
},
{
  title: 'Tag Configuration',
  icon: 'tag-configuration.svg',
  route: 'tag-master',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_TAG_CONFIGURATION,
},
{
  title: 'Special Category Configuration',
  icon: 'special-category.svg',
  route: 'special-category-config',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_SPECIAL_CATEGORY_CONFIGURATION,
},
{
  title: 'Cancellation Reason',
  icon: 'cancellation-reason.svg',
  route: 'cancellation-reason-config',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_CANCELLATION_REASON_CONFIG,
},
{
  title: 'Product Configuration',
  icon: 'product-configuration.svg',
  route: 'product-configuration',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_PRODUCT_CONFIGURATION,
},
{
  title: 'Subscription Details',
  icon: 'subscription.svg',
  route: 'subscription-details',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_SUBSCRIPTION_DETAILS,
},
{
  title: 'Subscription Menu Master',
  icon: 'subscription-menu.svg',
  route: 'subscription-menu-master',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_SUBSCRIPTION_MENU_MASTER,
},
{
  title: 'Reviews Tracking',
  icon: 'reviews-tracking.svg',
  route: 'reviews-tracking',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_REVIEWS_TRACKING,
},
{
  title: 'Party Order Details',
  icon: 'subscription.svg',
  route: 'party-order-details',
  abilityKey: AppAbilityKey.CUSTOMER_ADMIN_PARTY_ORDER_DETAILS,
},
].sort(sortByTitle);

export const BRAND_ADMIN_SIDEBAR_ITEMS: ISidebarItem[] = [
  {
    title: 'Dark Store Configuration',
    icon: 'icon-broadcast-message.svg',
    route: 'dark-store-config',
    abilityKey: AppAbilityKey.BRAND_ADMIN_DARK_STORE_CONFIGURATION,
  },
  {
    title: 'Profile Approval',
    icon: 'kitchenApproval.svg',
    route: 'brand-profile-approval',
    abilityKey: AppAbilityKey.BRAND_ADMIN_PROFILE_APPROVAL,
  }, {
    title: 'Product Approval',
    icon: 'menu-approval.svg',
    route: 'brand-product-approval',
    abilityKey: AppAbilityKey.BRAND_ADMIN_PRODUCT_APPROVAL,
  },
  {
    title: 'Brand Banner Management',
    icon: 'icon-banner-management.svg',
    route: 'brand-banner-management',
    abilityKey: AppAbilityKey.BRAND_ADMIN_BANNER_MANAGEMENT,
  }, {
    title: 'Brand Profile',
    icon: 'kitchenApproval.svg',
    route: 'brand-profile',
    abilityKey: AppAbilityKey.BRAND_ADMIN_BRAND_PROFILE,
  }, {
    title: 'Product Category',
    icon: 'product-category.svg',
    route: 'brand-product-category',
    abilityKey: AppAbilityKey.BRAND_ADMIN_PRODUCT_CATEGORY,
  },
  {
    title: 'View Brands',
    icon: 'view-cook.svg',
    route: 'view-brands',
    abilityKey: AppAbilityKey.BRAND_ADMIN_VIEW_BRAND,
  }, {
    title: 'Coupon Management',
    icon: 'coupon-management.svg',
    route: 'brand-coupon-management',
    abilityKey: AppAbilityKey.BRAND_ADMIN_COUPON_MANAGEMENT,
  }, {
    title: 'Orders History',
    icon: 'history.svg',
    route: 'orders-history',
    abilityKey: AppAbilityKey.COOK_ADMIN_ORDER_HISTORY,
    children: [
      {
        title: 'Todays Orders',
        route: 'todays-orders',
        abilityKey: AppAbilityKey.COOK_ADMIN_TODAYS_ORDERS,
      },
      {
        title: 'View All Orders',
        route: 'view-all-orders',
        abilityKey: AppAbilityKey.COOK_ADMIN_VIEW_ALL_ORDERS,
      }],
  },
].sort(sortByTitle);
