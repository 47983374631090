import { Injectable } from '@angular/core';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { environment } from '../../environments/environment';
import { ICreatedOrUpdatedBy } from './pre-booking-api.service';
import { IS3FileResponse } from '../upload.service';
import { IKitchenAssets } from './kitchen-api.service';
import { IFile } from './broadcast-message.service';

export enum SubscriptionType {
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  COMPLETED = 'completed',
  OUT_OF_ZONE = 'out-of-zone'
}

export enum DateFilterType {
  CONFIRMATION = 'confirmation',
  SUBSCRIPTION = 'subscription',
}

export enum SubscriptionProcessStatus {
  GENERATED_PAYMENT_LINK = 'generatedPaymentLink',
  CONTACTED = 'contacted',
  NO_RESPONSE = 'noResponse',
  INTERESTED = 'interested',
  NOT_INTERESTED = 'notInterested',
  OUT_OF_ZONE = 'outOfZone',
  ADMIN_APPROVED = 'adminApproved',
  SUBSCRIPTION_ENDED = 'subscriptionEnded',
}

export interface ILocation {
  type: string;
  coordinates: number[];
}

export interface IBillDetails {
  amount: number;
  mode: string;
  billedAmount: number;
  cgst: number;
  deliveryCharge: number;
  netAmount: number;
  roundOff: number;
  sgst: number;
  igst: number;
  savingsOnDiscount: number;
}

export interface IStatusLogs {
  _id: string;
  subscriptionId: string;
  status: string;
  processStatus: string;
  remarks: string;
  createdBy: ICreatedOrUpdatedBy;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface IPlans {
  breakfast: string;
  lunch: string;
  dinner: string;
}

export interface IReceiverDetails {
  name: string;
  phoneNumber: string;
}

export interface IAddons {
  addOnName: string;
  addOnDescription: string;
  addOnPrice: number;
  addOnId: string;
  quantity: number;
}

export interface IPlanDetails {
  subscriptionPlanId: string;
  subscriptionPlanName: string;
  itemPrice: number;
  quantity: number;
  mealTime: string;
  foodType: string;
  addOns: Array<IAddons>;
  deliveryTimeSlot: string;
  cuisine?: string;
}

export interface ISubscriptions {
  _id: string;
  subscriptionId: string;
  customerId?: string;
  name: string;
  phoneNumber: string;
  receiverDetails: IReceiverDetails;
  email: string;
  address: string;
  houseNo: string;
  street: string;
  landmark: string;
  city: string;
  pincode: string;
  location: ILocation;
  foodCategory: string;
  mealTime: Array<string>;
  cuisine: string;
  days: number;
  quantity: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  paymentId: string;
  startDate: string;
  endDate: string;
  billDetails: IBillDetails;
  isAdminApproved: boolean;
  isEligibleToGeneratePaymentLink: boolean;
  isFirstTimeCustomer: boolean;
  isRegisteredCustomer: boolean;
  ordersCount: number;
  statusLogs: Array<IStatusLogs>;
  parentZoneId: string;
  subZoneId: string;
  confirmedDate: string;
  addressId: string;
  processStatus: string;
  isDuplicateSubscription: boolean;
  orderStatus: string;
  subscriptionOrdersCount: number;
  __v: number;
  planValues: IPlans;
  notes: string;
  planDetails: Array<IPlanDetails>;
  orderAmount: number;
  deliveryDates: Array<string>;
  parentZoneName: string;
  activityStatus: string;
  specialInstructions: string;
}

export interface IAllSubscriptionResponse {
  subscriptions: Array<ISubscriptions>;
  subscriptionsCount: number;
  totalSubscriptionAmount: number;
}

// export interface ISubscriptionCities {
//   cities: Array<string>;
//   interStateCities: Array<string>;
// }

export interface IUpdateProcessStatusPayload {
  processStatus: string;
  status?: string;
  remarks: string;
}

export interface ICreateOrUpdateSubscriptionPayload {
  subscriptionId?: string;
  subscriptionOrderId?: string;
  orderDate?: string;
  startDate?: string;
  endDate?: string;
  mealTime: string;
  kitchenId: string;
  itemId: string;
  foodType: string;
  quantity: number;
  // addOns: string;
  specialInstruction: string;
  remarks: string;
  deliveryTimeSlot: string;
}

export interface IItemDetails {
  itemId: string;
  quantity: number;
  deliveryTimeSlot: string;
  foodType: string;
  itemName: string;
  itemCategory: string;
  price: number;
}

export interface IMealTimeSubscriptionDetails {
  _id: string;
  subscriptionId: string;
  subscriptionOrderId: string;
  status: string;
  orderDate: string;
  mealTime: string;
  kitchenId: string;
  itemDetails: Array<IItemDetails>;
  customerId: string;
  addressId: string;
  parentZoneId: string;
  subZoneId: string;
  deliveryTimeSlot: string;
  createdAt: string;
  updatedAt: string;
  specialInstruction: string;
  remarks: string;
  purchaseOrderStatus: string;
  addOns: Array<IAddons>;
  __v: number;
}

export interface IDateWiseSubscriptionDetails {
  breakfast: Array<IMealTimeSubscriptionDetails>;
  lunch: Array<IMealTimeSubscriptionDetails>;
  dinner: Array<IMealTimeSubscriptionDetails>;
  isOrderAlloted: boolean;
  isDelivered: boolean;
  deletedMealTimes: Array<string>;
}

export interface ISubscriptionDetails {
  subscription: ISubscriptions;
  subscriptionOrders: Record<string, IDateWiseSubscriptionDetails>;
}

export interface ISubscriptionOrder {
  subscriptionId: string;
  subscriptionOrderId: string;
  status: string;
  orderDate: string;
  mealTime: string;
  kitchenId: string;
  itemDetails: Array<IItemDetails>;
  customerId: string;
  addressId: string;
  parentZoneId: string;
  subZoneId: string;
  deliveryTimeSlot: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface IMealPlanPayload {
  filters: {
    dayOfWeek: string;
    mealTime: string;
    foodType: string;
    isActive: boolean;
    parentZoneId: string;
  };
}

export interface ISubscriptionMealPlan {
  _id: string;
  mealPlanId: string;
  dayOfWeek: string;
  mealTime: string;
  foodItems: Array<string>;
  foodType: string;
  isActive: true;
  parentZoneId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
export interface IUpdateSubscriptionDatePayload {
  subscriptionId: string;
  startDate: string;
  endDate: string;
}

export interface IDayWiseItemDetails {
  itemId?: string;
  itemName: string;
  description?: string;
}

export interface ISubscriptionMenuPayload {
  planName: string;
  planImageOriginalFileName: string;
  planImageS3FileName: string;
  itemPrice: number;
  itemDisplayPrice: number;
  itemChefRevenue: number;
  foodType: string;
  parentZoneId: string;
  mealTime: string;
  itemDetails: Record<string, IDayWiseItemDetails>;
  cuisine: Array<string>;
  subscriptionPlanId?: string;
  bulkOrderQuantity: number;
  bulkOrderPrice: number;
}

export interface ISubscriptionMenu {
  subscriptionPlanId: string;
  planName: string;
  foodType: string;
  parentZoneId: string;
  parentZoneName: string;
  mealTime: string;
  cuisine: Array<string>;
  itemDetails: Record<string, IDayWiseItemDetails>;
  itemPrice: number;
  itemDisplayPrice: number;
  itemChefRevenue: number;
  bulkOrderQuantity: number;
  bulkOrderPrice: number;
}

export interface IGetSubscriptionMenuPayload {
  filters: {
    parentZoneId?: string;
    foodType?: string;
    mealTime?: string | Array<string>;
    skip?: string;
    limit?: string;
    cuisine?: string;
  };
}

export interface ISubscriptionMenuPlan {
  _id: string;
  subscriptionPlanId: string;
  planName: string;
  planImageUrl: IFile;
  planImageOriginalFileName: string;
  planImageS3FileName: string;
  itemPrice: number;
  itemDisplayPrice: number;
  itemChefRevenue: number;
  bulkOrderQuantity: number;
  bulkOrderPrice: number;
  foodType: string;
  parentZoneId: string;
  parentZoneName: string;
  mealPlanIds: Array<string>;
  itemDetails: Record<string, IDayWiseItemDetails>;
  isActive: boolean;
  cuisine: Array<string>;
  mealTime: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface ISubscriptionMenuPlanResponse {
  subscriptionPlans: Array<ISubscriptionMenuPlan>,
  totalSubscriptionPlanCount: number;
}

export interface IGetSubscriptionKitchenPayload {
  filters: {
    'KitchenBusinessAttributes.isAcceptSubscription': boolean;
    parentZoneId: string;
  };
}

export interface IGetSubscriptionMasterMenuPayload {
  filters: {
    itemId: string;
  };
}

export interface Address {
  line1Address: string;
  line2Address: string;
  line3Address: string;
  state: string;
  nearestLocation: string;
  pincode: string;
  city?: string;
  location?: ILocation;
}

export interface ISubscriptionKitchen {
  _id: string;
  cookId: string;
  kitchenId?: string;
  logoImageS3FileName?: string;
  isKitchenInspected?: boolean;
  inspectionInfo?: string;
  logoImageOriginalFileName?: string;
  logoImageUrl?: IS3FileResponse;
  coverImageS3FileName?: string;
  coverImageOriginalFileName?: string;
  coverImageUrl?: IS3FileResponse;
  kitchenName?: string;
  kitchenDescription?: string;
  address: Address;
  activeMeals?: {
    breakfast: boolean;
    lunch: boolean;
    snacks: boolean;
    dinner: boolean;
  };
  foodCuisineStyle?: Array<string>;
  foodType?: Array<string>;
  isHalal?: boolean;
  kitchenAvailability?: any;
  isActive?: boolean;
  subZoneId?: string;
  parentZoneId?: string;
  isForceDisabled?: boolean;
  statusUpdatedBy?: ICreatedOrUpdatedBy;
  statusUpdatedOn?: Date;
  tags?: string;
  assets?: Array<IKitchenAssets>;
  product?: {
    kitchenName: string;
    logoImageOriginalFileName: string;
    logoImageS3FileName: string;
    logoImageUrl?: IS3FileResponse;
    assets: Array<IKitchenAssets>;
    revenueSharingPercentage?: number;
    satelliteStoreId?: string;
    isActive: boolean;
  };
  kitchenRatings?: {
    avgRating: number;
    totalReviewers: number;
  };
  isMig: boolean;
  createdAt: string;
  updatedAt: string;
  __v: 0;
  KitchenBusinessAttributes: {
    isAcceptSubscription: boolean;
  };
  kitchenDistance?: string;
}

export interface ISubscriptionMasterMenu {
  _id: string;
  title: string;
  description: string;
  itemImageS3FileName: string;
  itemImageOriginalFileName: string;
  itemCategory: string;
  active: boolean;
  price: number;
  displayPrice: number;
  itemDetails: string;
  masterQuantity: number;
  foodType: string;
  preferredMealTime: Array<string>;
  itemId: string;
  preparationTime: number;
  isBulkPreparable: boolean;
  menuType: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface IUpdateCustomerLocationPayload {
  city: string;
  houseNo: string;
  street: string;
  address: string;
  pincode: string;
  landmark: string;
  lat: number;
  long: number;
}

export interface ISubscriptionOrderLogs {
  status: string;
  updatedBy: ICreatedOrUpdatedBy;
  updatedAt: string;
  specialInstruction: string;
  deliveryTimeSlot: string;
  formattedDeliveryTimeSlot?: string;
  kitchenName: string;
  kitchenId: string;
  itemName: string;
  addOns: Array<IAddons>;
  itemId: string;
  remarks: string;
}

export interface ISubscriptionAddonsPayload {
  addOnName: string;
  addOnDescription: string;
  addOnPrice: number;
  isActive: boolean;
  addOnId?: string;
}

export interface ISubscriptionAddons {
  addOnName: string;
  addOnDescription: string;
  addOnPrice: number;
  addOnId: string;
  isActive: boolean;
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface ISubscriptionAddonsResponse {
  addOns: Array<ISubscriptionAddons>;
  totalAddOnsCount: number;
}

export interface IFetchSubscriptionAddonsPayload {
  filters: {
    parentZoneId?: string;
    cuisineName?: string;
  };
}

export interface ISubscriptionCuisinePayload {
  cuisineName: string;
  cuisineDescription: string;
  cuisineImageS3FileName: string;
  cuisineImageOriginalFileName: string;
  isActive: boolean;
}

export interface ISubscriptionCuisines {
  _id: string;
  cuisineId: string;
  cuisineName: string;
  cuisineDescription: string;
  cuisineImageS3FileName: string;
  cuisineImageOriginalFileName: string;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
  cuisineImageUrl: IFile;
  __v: number;
}

export interface ISubscriptionCuisinesResponse {
  cuisines: Array<ISubscriptionCuisines>;
  totalCuisinesCount: number;
}

@Injectable()
export class SubscriptionService extends RestApiService {
  protected subscriptionApiUrl = environment.config['SUBSCRIPTION_SERVICE_URL'];

  protected kitchenApiUrl = environment.config['KITCHEN_SERVICE_URL'];

  private servicePath = `${this.subscriptionApiUrl}/subscriptions`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getAllSubscriptions(subscriptionType: string, queryParams: Partial<any>)
    : Promise<{ data: IAllSubscriptionResponse; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', queryParams['parentZoneId']);
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', queryParams['subZoneId']);
      }
      if (queryParams['city']) {
        searchParams.set('city', String(queryParams['city']));
      }
      if (queryParams['status']) {
        searchParams.set('status', String(queryParams['status']));
      }
      if (queryParams['orderStatus']) {
        searchParams.set('orderStatus', String(queryParams['orderStatus']));
      }
      if (queryParams['processStatus']) {
        searchParams.set('processStatus', String(queryParams['processStatus']));
      }
      if (queryParams['mealTime']) {
        searchParams.set('mealTime', String(queryParams['mealTime']));
      }
      if (queryParams['dateFilterType']) {
        searchParams.set('dateFilterType', String(queryParams['dateFilterType']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if ('search' in queryParams) {
        searchParams.set('search', String(queryParams['search']));
      } else {
        searchParams.delete('search');
      }
    }
    const apiPath = `${this.servicePath}/${subscriptionType}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async generatePaymentLink(subscriptionId: string) {
    const apiPath = `${this.servicePath}/${subscriptionId}/initiate-payment`;
    return this.httpPatch(apiPath);
  }

  async updateApprovalStatus(subscriptionId: string) {
    const apiPath = `${this.servicePath}/${subscriptionId}/approved`;
    return this.httpPatch(apiPath);
  }

  async getAllCities(): Promise<{ data: Array<string>; }> {
    const apiPath = `${this.servicePath}/cities`;
    return this.httpGet(apiPath);
  }

  async updateProcessStatus(subscriptionId: string, payload: IUpdateProcessStatusPayload) {
    const apiPath = `${this.servicePath}/${subscriptionId}/process-status`;
    return this.httpPut(apiPath, payload);
  }

  async cancelSubscription(subscriptionId: string, payload: { remarks: string; }) {
    const searchParams = new URLSearchParams();
    if (subscriptionId) {
      searchParams.set('subscriptionId', String(subscriptionId));
    }
    const apiPath = `${this.servicePath}/cancel?${searchParams.toString()}`;
    return this.httpPatch(apiPath, payload);
  }

  async closeSubscription(subscriptionId: string, payload: { remarks: string; }) {
    const searchParams = new URLSearchParams();
    if (subscriptionId) {
      searchParams.set('subscriptionId', String(subscriptionId));
    }
    const apiPath = `${this.servicePath}/close?${searchParams.toString()}`;
    return this.httpPatch(apiPath, payload);
  }

  async completeSubscription(subscriptionId: string, payload: { remarks: string; }) {
    const searchParams = new URLSearchParams();
    if (subscriptionId) {
      searchParams.set('subscriptionId', String(subscriptionId));
    }
    const apiPath = `${this.servicePath}/complete?${searchParams.toString()}`;
    return this.httpPatch(apiPath, payload);
  }

  async addNotes(subscriptionId: string, payload: { notes: string; }) {
    const searchParams = new URLSearchParams();
    if (subscriptionId) {
      searchParams.set('subscriptionId', String(subscriptionId));
    }
    const apiPath = `${this.servicePath}/notes?${searchParams.toString()}`;
    return this.httpPost(apiPath, payload);
  }

  // Subscription Calendar Order API's
  async createSingleSubscriptionOrder(payload: ICreateOrUpdateSubscriptionPayload): Promise<{ data: ISubscriptionOrder; }> {
    const apiPath = `${this.subscriptionApiUrl}/subscription-orders/create`;
    return this.httpPost(apiPath, payload);
  }

  async updateSingleSubscriptionOrder(payload: ICreateOrUpdateSubscriptionPayload): Promise<{ data: ISubscriptionOrder; }> {
    const apiPath = `${this.subscriptionApiUrl}/subscription-orders/update`;
    return this.httpPut(apiPath, payload);
  }

  async createMultipleSubscriptionOrder(payload: ICreateOrUpdateSubscriptionPayload) {
    const apiPath = `${this.subscriptionApiUrl}/subscription-orders/apply-all`;
    return this.httpPost(apiPath, payload);
  }

  async getSubscriptionDetailsById(subscriptionId: string): Promise<{ data: ISubscriptionDetails; }> {
    const apiPath = `${this.subscriptionApiUrl}/subscription-orders/details/${subscriptionId}`;
    return this.httpGet(apiPath);
  }

  async updateSubscriptionDateSubscription(payload: IUpdateSubscriptionDatePayload) {
    const apiPath = `${this.subscriptionApiUrl}/subscriptions/update/period`;
    return this.httpPut(apiPath, payload);
  }

  async deleteSubscription(subscriptionOrderId: string, excludeRestriction?: boolean, payload?: { remarks: string; }) {
    const searchParams = new URLSearchParams();
    if (excludeRestriction) {
      searchParams.set('excludeRestriction', String(excludeRestriction));
    }
    const apiPath = `${this.subscriptionApiUrl}/subscription-orders/${subscriptionOrderId}?${searchParams.toString()}`;
    return this.httpDelete(apiPath, payload);
  }

  async getSubscriptionOrderLogs(subscriptionId: string, queryParams: Partial<any>): Promise<{ data: Array<ISubscriptionOrderLogs>; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['mealTime']) {
        searchParams.set('mealTime', String(queryParams['mealTime']));
      }
      if (queryParams['orderDate'] !== undefined) {
        searchParams.set('orderDate', String(queryParams['orderDate']));
      }
    }
    const apiPath = `${this.subscriptionApiUrl}/subscription-orders/${subscriptionId}/order-logs?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  // Subscription Meal Plans
  async fetchSubscriptionMealPlan(payload: IMealPlanPayload): Promise<{ data: Array<ISubscriptionMealPlan>; }> {
    const apiPath = `${this.subscriptionApiUrl}/meal-plans/fetch`;
    return this.httpPost(apiPath, payload);
  }

  // Subscription Menu Master API's
  async createSubscriptionMenu(payload: ISubscriptionMenuPayload): Promise<{ data: ISubscriptionMenu; }> {
    const apiPath = `${this.subscriptionApiUrl}/subscription-plans/create`;
    return this.httpPost(apiPath, payload);
  }

  async updateSubscriptionMenu(payload: ISubscriptionMenuPayload): Promise<{ data: ISubscriptionMenu; }> {
    const apiPath = `${this.subscriptionApiUrl}/subscription-plans/update`;
    return this.httpPut(apiPath, payload);
  }

  async fetchAllSubscriptionMenu(queryParams: Partial<any>, payload: IGetSubscriptionMenuPayload)
    : Promise<{ data: ISubscriptionMenuPlanResponse; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
    }
    const apiPath = `${this.subscriptionApiUrl}/subscription-plans/fetch/list?${searchParams.toString()}`;
    return this.httpPost(apiPath, payload);
  }

  async fetchSubscriptionMenuById(subscriptionPlanId: string): Promise<{ data: ISubscriptionMenuPlan; }> {
    const apiPath = `${this.subscriptionApiUrl}/subscription-plans/${subscriptionPlanId}`;
    return this.httpGet(apiPath);
  }

  async fetchSubscriptionKitchen(payload: IGetSubscriptionKitchenPayload): Promise<{ data: Array<ISubscriptionKitchen>; }> {
    const apiPath = `${this.kitchenApiUrl}/kitchens/get-kitchens-details`;
    return this.httpPost(apiPath, payload);
  }

  async fetchSubscriptionMasterMenu(payload: IGetSubscriptionMasterMenuPayload): Promise<{ data: Array<ISubscriptionMasterMenu>; }> {
    const apiPath = `${this.kitchenApiUrl}/master-menus/fetch`;
    return this.httpPost(apiPath, payload);
  }

  async updateMasterMenuStatus(subscriptionPlanId: string, payload: { isActive: boolean; }): Promise<{ data: ISubscriptionMenu; }> {
    const apiPath = `${this.subscriptionApiUrl}/subscription-plans/${subscriptionPlanId}/status`;
    return this.httpPatch(apiPath, payload);
  }

  async updateCustomerLocation(subscriptionId: string, payload: IUpdateCustomerLocationPayload) {
    const searchParams = new URLSearchParams();
    if (subscriptionId) {
      searchParams.set('subscriptionId', String(subscriptionId));
    }
    const apiPath = `${this.subscriptionApiUrl}/subscriptions/update-location?${searchParams.toString()}`;
    return this.httpPost(apiPath, payload);
  }

  // Subscription Addons API's
  async createSubscriptionAddons(payload: ISubscriptionAddonsPayload): Promise<{ data: ISubscriptionAddons; }> {
    const apiPath = `${this.subscriptionApiUrl}/subscription-add-ons`;
    return this.httpPost(apiPath, payload);
  }

  async updateSubscriptionAddons(payload: ISubscriptionAddonsPayload): Promise<{ data: ISubscriptionAddons; }> {
    const apiPath = `${this.subscriptionApiUrl}/subscription-add-ons`;
    return this.httpPut(apiPath, payload);
  }

  async fetchSubscriptionAddons(queryParams: Partial<any>, payload: IFetchSubscriptionAddonsPayload): Promise<{ data: ISubscriptionAddonsResponse; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
    }
    const apiPath = `${this.subscriptionApiUrl}/subscription-add-ons/fetch-all?${searchParams.toString()}`;
    return this.httpPost(apiPath, payload);
  }

  // Subscription Cuisine API's
  async createSubscriptionCuisine(payload: ISubscriptionCuisinePayload): Promise<{ data: ISubscriptionCuisines; }> {
    const apiPath = `${this.subscriptionApiUrl}/subscription-cuisine-details`;
    return this.httpPost(apiPath, payload);
  }

  async updateSubscriptionCuisine(payload: ISubscriptionCuisinePayload, cuisineId: string): Promise<{ data: ISubscriptionCuisines; }> {
    const apiPath = `${this.subscriptionApiUrl}/subscription-cuisine-details/${cuisineId}`;
    return this.httpPatch(apiPath, payload);
  }

  async fetchSubscriptionCuisines(queryParams: Partial<any>, payload: IFetchSubscriptionAddonsPayload): Promise<{ data: ISubscriptionCuisinesResponse; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
    }
    const apiPath = `${this.subscriptionApiUrl}/subscription-cuisine-details/fetch-all?${searchParams.toString()}`;
    return this.httpPost(apiPath, payload);
  }
}
